import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Root from "../components/Root";
import SEO from "../components/seo";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      margin: "40px 0px",
    },
    [theme.breakpoints.up("md")]: {
      margin: "60px 0px",
    },
  },
  container: {
    background: theme.palette.background.default,
  },
  header: {
    marginBottom: "40px",
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  paragraph: {
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: "1rem",
  },
}));

const TermsPage = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Terms and conditions
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            These terms and conditions ("Terms", "Agreement") are an agreement between WhereToMine ("WhereToMine", "us", "we" or "our") and
            you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of your use of the wheretomine.io
            website and any of its products or services (collectively, "Website" or "Services").
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Backups
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            We are not responsible for Content residing on the Website. In no event shall we be held liable for any loss of any Content. It
            is your sole responsibility to maintain appropriate backup of your Content. Notwithstanding the foregoing, on some occasions and
            in certain circumstances, with absolutely no obligation, we may be able to restore some or all of your data that has been
            deleted as of a certain date and time when we may have backed up data for our own purposes. We make no guarantee that the data
            you need will be available.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Links to other websites
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            Although this Website may link to other websites, we are not, directly or indirectly, implying any approval, association,
            sponsorship, endorsement, or affiliation with any linked website, unless specifically stated herein. We are not responsible for
            examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their websites.
            We do not assume any responsibility or liability for the actions, products, services, and content of any other third-parties.
            You should carefully review the legal statements and other conditions of use of any website which you access through a link from
            this Website. Your linking to any other off-site websites is at your own risk.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Prohibited uses
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            In addition to other terms as set forth in the Agreement, you are prohibited from using the Website or its Content: (a) for any
            unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international,
            federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual
            property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage,
            intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;
            (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will
            or may be used in any way that will affect the functionality or operation of the Service or of any related website, other
            websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext,
            spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features
            of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the
            Service or any related website for violating any of the prohibited uses.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Intellectual property rights
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            This Agreement does not transfer to you any intellectual property owned by WhereToMine or third-parties, and all rights, titles,
            and interests in and to such property will remain (as between the parties) solely with WhereToMine. All trademarks, service
            marks, graphics and logos used in connection with our Website or Services, are trademarks or registered trademarks of
            WhereToMine or WhereToMine licensors. Other trademarks, service marks, graphics and logos used in connection with our Website or
            Services may be the trademarks of other third-parties. Your use of our Website and Services grants you no right or license to
            reproduce or otherwise use any WhereToMine or third-party trademarks.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Limitation of liability
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            To the fullest extent permitted by applicable law, in no event will WhereToMine, its affiliates, officers, directors, employees,
            agents, suppliers or licensors be liable to any person for (a): any indirect, incidental, special, punitive, cover or
            consequential damages (including, without limitation, damages for lost profits, revenue, sales, goodwill, use or content, impact
            on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory
            of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even
            if WhereToMine has been advised as to the possibility of such damages or could have foreseen such damages. To the maximum extent
            permitted by applicable law, the aggregate liability of Website Operator and its affiliates, officers, employees, agents,
            suppliers and licensors, relating to the services will be limited to an amount greater of one dollar or any amounts actually
            paid in cash by you to WhereToMine for the prior one month period prior to the first event or occurrence giving rise to such
            liability. The limitations and exclusions also apply if this remedy does not fully compensate you for any losses or fails of its
            essential purpose.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Indemnification
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            You agree to indemnify and hold WhereToMine and its affiliates, directors, officers, employees, and agents harmless from and
            against any liabilities, losses, damages or costs, including reasonable attorneys' fees, incurred in connection with or arising
            from any third-party allegations, claims, actions, disputes, or demands asserted against any of them as a result of or relating
            to your Content, your use of the Website or Services or any willful misconduct on your part.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Severability
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            All rights and restrictions contained in this Agreement may be exercised and shall be applicable and binding only to the extent
            that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render
            this Agreement illegal, invalid or unenforceable. If any provision or portion of any provision of this Agreement shall be held
            to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the parties that the
            remaining provisions or portions thereof shall constitute their agreement with respect to the subject matter hereof, and all
            such remaining provisions or portions thereof shall remain in full force and effect.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Dispute resolution
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            The formation, interpretation, and performance of this Agreement and any disputes arising out of it shall be governed by the
            substantive and procedural laws of Bukarest, Romania without regard to its rules on conflicts or choice of law and, to the
            extent applicable, the laws of Romania. The exclusive jurisdiction and venue for actions related to the subject matter hereof
            shall be the state and federal courts located in Bukarest, Romania, and you hereby submit to the personal jurisdiction of such
            courts. You hereby waive any right to a jury trial in any proceeding arising out of or related to this Agreement. The United
            Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Changes and amendments
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            We reserve the right to modify this Agreement or its policies relating to the Website or Services at any time, effective upon
            posting of an updated version of this Agreement on the Website. When we do, we will revise the updated date at the bottom of
            this page. Continued use of the Website after any such changes shall constitute your consent to such changes.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Acceptance of these terms
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Website or its
            Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not
            authorized to use or access the Website and its Services.
          </Typography>
        </div>

        <div className={classes.header}>
          <Typography className={classes.headerTitle} variant="h1">
            Contacting us
          </Typography>
          <Typography className={classes.paragraph} variant="subtitle">
            If you have any questions about this Agreement, please contact us at contact@wheretomine.io.
            <br />
            This document was last updated on April 30, 2020
          </Typography>
        </div>
      </Container>
    </div>
  );
};

const Terms = () => {
  return (
    <Root>
      <SEO title="Terms and Conditions" description="The terms and conditions for using the services provided by WhereToMine." />
      <TermsPage />
    </Root>
  );
};

export default Terms;
